import type { Maybe, ValueOf } from '@evc/web-components';
import { objectMap } from '@evc/web-components';

import type { PlatformEnv } from '../../providers/env.type';
import { getUrisConfig } from '../../services/config/config.default';
import type { ApplicationURIs } from '../../services/config/config.type';
import type { AuthAuthorities, AuthConfig, AuthPolicies, AuthRedirects } from './auth.type';

/** compute clean config for auth based on some .env config
 * this one is special as it is use by authProviders
 * important to note that environment will not be parsed and defaulted before this
 */
export function getAuthConfig(environment:PlatformEnv):Maybe<AuthConfig> {
  const {
    GREENFIELD,
    ENV,
    AUTH_CLIENT_ID,
    AUTH_FORCE_ORGANIZATION,
    AUTH_ENABLE_SESSION_ON_RELOAD,
  } = environment ?? {};
  if (!GREENFIELD) return undefined;

  const env = ENV;
  const clientId = AUTH_CLIENT_ID;
  const forceOrganization = AUTH_FORCE_ORGANIZATION || false;
  const enableSessionOnReload = AUTH_ENABLE_SESSION_ON_RELOAD || false;

  const authorityDomain = (() => ({
    local: 'login.dev-evcnt.com',
    development: 'login.dev-evcnt.com',
    testing: 'login.dev-evcnt.com',
    staging: 'login.stg-evcnt.com',
    production: 'login.tnmconnect.com',
  }[env]))();

  const authorityTenantId = (() => ({
    local: 'f57d71df-07fa-43bd-ad31-28f8609df92b',
    development: 'f57d71df-07fa-43bd-ad31-28f8609df92b',
    testing: 'f57d71df-07fa-43bd-ad31-28f8609df92b',
    staging: 'bfcee7e6-a77b-4af3-ac8d-10b277b0b91a',
    production: '3eb3cfcf-c897-4c07-913e-4f352afc0e65',
  }[env]))();

  if (!clientId || !authorityDomain || !authorityTenantId) return undefined;

  const policies:AuthPolicies = {
    signUpSignIn: 'b2c_1a_signup_signin',
    resetPassword: 'b2c_1a_passwordreset',
    // editProfile: 'b2c_1a_profileedit',
  };

  const uris:ApplicationURIs = getUrisConfig(environment);

  const redirects:AuthRedirects = {
    success: uris.root!,
    fail: uris.logout ?? uris.public!,
    organization: uris.organization!,
  };

  const AUTHORITY_DOMAIN = authorityDomain;
  const AUTHORITY_URI = `https://${authorityDomain}/${authorityTenantId}/`/* + policyName */;

  return {
    clientId,
    authorityDomain,
    authorityTenantId,
    policies,
    redirects,
    forceOrganization,
    enableSessionOnReload,
    scopes: [clientId],
    b2cPolicies: {
      names: policies,
      authorities: objectMap(policies, (policy: ValueOf<AuthAuthorities>) => `${AUTHORITY_URI}${policy}`),
      authorityDomain: AUTHORITY_DOMAIN,
    },
  };
}
