/** App config
 * - define idp endpoints, tenants name etc for each environment
 * - then compute MSAL config from it
 *
 * @example app.config.ts
 * ```ts
 * import { environment } from '@env';
 * import { provideEnv, getAuthProviders } from '@evc/platform';
 *
 * export const appConfig: ApplicationConfig = {
 *   providers: [
 *     provideEnv(environment),
 *     ...getAuthProviders(environment),
 *   ],
 * };
 * ```
 */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import type { Provider } from '@angular/core';
import { InjectionToken } from '@angular/core';
import type {
  MsalGuardAuthRequest,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import type { IPublicClientApplication } from '@azure/msal-browser';
import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

import { ENV_VARIABLES } from '@evc/web-components';

import type { PlatformEnv } from '../../../providers/env.type';
import { getAuthConfig } from '../auth.config';
import type { AuthConfig } from '../auth.type';

/* way to inject config from a callback to pass .env config : see getAuthProviders()*/
export const AUTH_CONFIG = new InjectionToken<string>('AUTH_CONFIG');

export const STORAGE_KEYS = {
  /* used in session to enforce to stay same org when reload current tabs */
  toid: 'tnmconnect-redirect-toid',
};

/** generate needed providers for MSAL - to be imported in app.config.ts */
export function getAuthProviders(environment?:PlatformEnv&Record<string, unknown>):Provider[] {
  if (!environment?.GREENFIELD) return [];

  return [
    {
      provide: AUTH_CONFIG,
      useFactory: getAuthConfig,
      deps: [ENV_VARIABLES],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor, // you can set your own @see exemples/_auth.intercepror.ts,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [ENV_VARIABLES, AUTH_CONFIG],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [ENV_VARIABLES, AUTH_CONFIG],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [ENV_VARIABLES, AUTH_CONFIG],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ];
}

function MSALInstanceFactory(environment:PlatformEnv, config:AuthConfig): IPublicClientApplication {
  const { ENV } = environment ?? {};

  const { clientId, redirects, b2cPolicies } = config ?? {};

  const logLevel = (() => {
    switch (ENV) {
      case 'production': return LogLevel.Error;
      case 'staging': return LogLevel.Warning;
      default: return LogLevel.Verbose;
    }
  })();

  return new PublicClientApplication({
    auth: {
      authority: b2cPolicies.authorities.signUpSignIn as string,
      knownAuthorities: [b2cPolicies.authorityDomain],
      clientId,
      redirectUri: redirects.success, // current app
      postLogoutRedirectUri: redirects.fail, // public home
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowRedirectInIframe: true, // Needed for Logout Front Channel
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        logLevel,
        piiLoggingEnabled: false,
      },
    },
  });
}

function MSALInterceptorConfigFactory(environment:PlatformEnv, config:AuthConfig): MsalInterceptorConfiguration {
  const { CORE_API_URI, DEVICE_API_URI } = environment ?? {};

  const { scopes } = config ?? {};
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(CORE_API_URI!, scopes);

  if (DEVICE_API_URI) {
    protectedResourceMap.set(DEVICE_API_URI!, scopes);
  }

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

function MSALGuardConfigFactory(environment:PlatformEnv, config:AuthConfig): MsalGuardConfiguration {
  const { LOCALES_STORAGE_KEY } = environment ?? {};

  const { scopes, b2cPolicies } = config ?? {};
  const { AUTH_FAIL_ROUTE } = environment;

  const extraQueryParameters:Record<string, string> = {};

  const authRequest:MsalGuardAuthRequest & { extraQueryParameters:Record<string, string> } = {
    authority: b2cPolicies.authorities.signUpSignIn as string,
    scopes: [...scopes],
    extraQueryParameters,
  };

  const configuration:MsalGuardConfiguration & { authRequest:MsalGuardAuthRequest } = {
    interactionType: InteractionType.Redirect,
    authRequest,
    loginFailedRoute: AUTH_FAIL_ROUTE ?? 'auth-fail',
  };

  const { AUTH_CAPTCHA_BYPASS_TOKEN } = environment;
  if (AUTH_CAPTCHA_BYPASS_TOKEN && AUTH_CAPTCHA_BYPASS_TOKEN !== '{IAC_AADB2C_CAPTCHA_BYPASS_TOKEN}') {
    configuration.authRequest!.extraQueryParameters!.cbt = AUTH_CAPTCHA_BYPASS_TOKEN;
  }

  const lang = getLanguage(LOCALES_STORAGE_KEY);
  if (lang) {
    configuration.authRequest!.extraQueryParameters!.ui_locales = lang;
  }

  return configuration;
}

function getLanguage(storageKey?:string): string|null {
  return getLangFromUrl()
    || getLangFromStorage(storageKey)
    || getLangFromBrowser();
}

function getLangFromUrl(): string|null {
  return new URL(window.location.href).searchParams.get('lang');
}

function getLangFromStorage(storageKey='evc_user_lang'): string|null {
  return localStorage.getItem(storageKey);
}

function getLangFromBrowser(): string|null {
  const locale = navigator.languages
    ? navigator.languages[0]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    : (navigator.language || (navigator as any).userLanguage as string);

  return locale?.split('-')[0];
}

export default getAuthProviders;
