/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ValueOf } from './types';

type MapFunction<T, U> = (value: T, key: string, index: number) => U;
/** Array.map but for objects - iterate on each keys and you can update values
 * `objectMap(inputObject, (key, value) => `updated key value`);`
 */
export function objectMap<T extends Record<string, unknown>, U>(obj: T, fn: MapFunction<ValueOf<T>, U>): Record<string, U> {
  return Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v as ValueOf<T>, k, i)],
    ),
  );
}

type TransformFunction<T, U> = (value: T, key: string, index: number) => U;
/** remap an object to another
 * `objectTransform<InputType, OutputType>(inputObject, ({ id, firstname, lastname }) => ({ id, name: `${firstname} ${lastname}` }));`
 */
export function objectTransform<T extends Record<string, unknown>, U>(obj: T, fn: TransformFunction<T, U>): U {
  return Object.entries(obj).reduce((acc, [key, value], index) => {
    const mappedValue = fn(value as T, key, index);

    return { ...acc, ...mappedValue };
  }, {} as U);
}

export function isArray(payload: unknown): payload is unknown[] {
  return Array.isArray(payload);
}

export function isObject(payload: unknown): payload is Record<string, unknown> {
  return payload !== null && typeof payload === 'object';
}

export type CompareFunction<T> = (payload: unknown) => payload is T;
export function isAll<T>(compareFn:CompareFunction<T>, payloads: unknown[]): payloads is T[] {
  return payloads.every(compareFn);
}

/**
* Performs a deep merge of objects and returns new object.
* - (immutable) return copy - do not modify input
* - dont merge if undefined - but null does erase a value
*
* @param {...items} objects[] - Objects to merge
* @param option Option - last object may be some merge option
* @returns {object} New object with merged key/values
*/
export function deepMerge<T extends Record<string, unknown>>(...items: any[]): T {
  const { list, mergeOptions } = fetchItemsAndOptions(items);

  return list.reduce((prev, current) => {
    Object.keys(current).forEach(key => {
      const oldVal = prev[key];
      const newVal = current[key];
      const values = [oldVal, newVal];

      if (isAll(isArray, values)) {
        prev[key] = mergeOptions.mergeArrays
          ? Array.from(new Set(values.flat()))
          : prev[key] = newVal;
      } else if (isAll(isObject, values)) {
        prev[key] = deepMerge(oldVal, newVal, mergeOptions);
      } else if (newVal !== undefined) {
        prev[key] = newVal;
      }
    });

    return prev;
  }, {} as T);
}

export type MergeOption = {
  mergeArrays?:boolean,
}
function fetchItemsAndOptions(items: any[]):{
  list: any[],
  mergeOptions: MergeOption
} {
  const lastItem = items.length > 1 && items[items.length - 1];
  const isOption = typeof lastItem.mergeArrays === 'boolean';

  const options:MergeOption = {
    mergeArrays: false,
    ...(isOption ? lastItem : {}),
  };
  const itemsNoOption = isOption ? items.slice(0, -1) : items;

  return {
    mergeOptions: options,
    list: itemsNoOption,
  };
}

/** flatten json so each keys are concat like {menus.applications.links.home:"Accueil", ...} */
export function flattenJSON(data: Record<string, any>, parentKey = '', result: Record<string, string> = {}): Record<string, string> {
  for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
          const newKey = parentKey ? `${parentKey}.${key}` : key;
          if (typeof data[key] === 'object' && data[key] !== null) {
              flattenJSON(data[key], newKey, result);
          } else {
              result[newKey] = data[key];
          }
      }
  }

  return result;
}

/**
 * Replaces placeholders in a template string with corresponding values from a params object.
 *
 * Placeholders in the template should be wrapped in double curly braces, e.g., `{{key}}`.
 * If a key in the template does not exist in the params object, it will be replaced with an empty string.
 *
 * Example:
 * ```typescript
 * const template = "Hello, {{name}}!";
 * const params = { name: "John" };
 * const result = replaceParams(template, params); // "Hello, John!"
 * ```
 *
 * @param template - The template string containing placeholders.
 * @param params - An object containing key-value pairs to replace placeholders in the template.
 * @returns The template string with placeholders replaced by corresponding values from the params object.
 */
export function replaceParams(template: string, params: { [key: string]: string }): string {
  return template.replace(/\{\{(\w+)\}\}/g, (_, key) => params[key] || '');
}
