/**
 * Generate default config for platform
 */
import type { Maybe, Menu, ModalService, ThemeService } from '@evc/web-components';
import { BtnActionEnum } from '@evc/web-components';

import type { PlatformEnv } from '../../providers/env.type';
import type { I18nPlatformService } from '../../services/i18n/i18n.service';
import type { ApiConfig, ApplicationURIs, I18nConfig, PlatformConfig } from './config.type';

export function defaultPlatformConfigFactory(
  environment:PlatformEnv,
  themeService: ThemeService,
  modalService: ModalService,
  i18nPlatformService: I18nPlatformService,
):Partial<PlatformConfig> {
  const helpMenu:Menu = {
    title: i18nPlatformService.t$('menus.help.title'),
    items: [
      {
        // ? idea : add optional key `enabled` - if defined as false will skip this item
        text: i18nPlatformService.t$('menus.help.about'),
        key: 'about',
        action: {
          type: BtnActionEnum.CALLBACK,
          callbackOrUrl: (): void => {
            modalService.showCurrentModal('about');
          },
        },
      },
    ],
  };

  /** default setings will define theme management
   * - may be merge with extra menuItems (rendered before themes)
   * - handle item with key `advanced-settings` to be forced to be last (after themes)
   */
  const themes = ['light', 'dark', 'auto'] as const;
  const settingsMenu: Menu = {
    title: i18nPlatformService.t$('menus.settings.title'),
    items: [
      {
        text: i18nPlatformService.t$('menus.settings.themes.title'),
        key: 'theme',
        collapsible: themes.map((theme) => ({
          text: i18nPlatformService.t$(`menus.settings.themes.${theme}`),
          key: theme,
          img: `/assets/theme/${theme}.webp`,
          selected: theme === 'auto',
          action: {
            type: BtnActionEnum.CALLBACK,
            callbackOrUrl: (): void => {
              themeService.theme = theme;
            },
          },
        })),
      },
    ],
  };

  const leftbarMenus: Menu[] = [];

  const env = environment.ENV;

  return {
    env,
    /** application basic infos
     * used in metas, logo in navbar etc
     *
     * + IDEAS : favicon, keywords, description...
     */
    app: {
      name: 'evident-connect',
      title: 'Evident Connect',
      // TODO fix logo filename !
      logo: '/assets/apps/withe_icon_unis 1.svg#withe_icon_unis 1',
    },
    menus: {
      settings: settingsMenu,
      help: helpMenu,
      leftbar: leftbarMenus,
    },
    applications: [],
    uris: getUrisConfig(environment),
    theme: 'auto',
  };
}

/** specific links for some specific buttons in topbar
 * - if not defined - will compute those per env (see config.service)
 * - if defined (input) will be overrided
 */
export function getUrisConfig(environment:PlatformEnv):ApplicationURIs {
  const { ENV } = environment;
  const domain = (() => {
    switch (ENV) {
      case 'local':
      case 'testing':
        return 'localhost';
      case 'development':
        return 'dev-evcnt.com';
      case 'staging':
        return 'stg-evcnt.com';
      default:
        return 'tnmconnect.com';
    }
  })();

  const perEnvUris = (() => {
    switch (ENV) {
      case 'local':
      case 'testing':
        return {
          homepage: `http://${domain}:8080`,
          organization: `http://${domain}:8081`,
          user: `http://${domain}:8082`,
          admin: `http://${domain}:8083`,
          drive: `http://${domain}:8084`,
          equipment: `http://${domain}:8085`,
          marketplace: `http://${domain}:8086`,
          licenses: `http://${domain}:8087`,
        };
      default:
        return {
          homepage: `https://home.${domain}`,
          organization: `https://organization.${domain}`,
          user: `https://user.${domain}`,
          admin: `https://admin.${domain}`,
          equipment: `https://equipment-manager.${domain}`,
          drive: `https://drive.${domain}`,
          marketplace: `https://marketplace.${domain}`,
          licenses: `https://licenses.${domain}`,
        };
    }
  })();

  return {
    root: window?.location.origin ?? '/',
    public: perEnvUris.marketplace,
    logout: perEnvUris.homepage,
    ...perEnvUris,
  };
}

/** compute clean config for api based on some .env config */
export function getApiConfig(environment:PlatformEnv):Maybe<ApiConfig> {
  if (!environment.GREENFIELD) return undefined;

  return {
    uri: environment.CORE_API_URI!,
    endpoints: {
      signupsignin: 'v1/idm/signupsignin',
      user: {
        me: 'v1/user/me',
        applications: 'v1/user/me/products',
      },
      organization: {
        create: 'v1/organization/create',
        list: 'v1/organization/user-organizations',
        current: 'v1/organization/current',
        exists: 'v1/organization/exists',
        listUsers: 'v1/organization/users',
        sendInvitation: 'v1/organization/user/send-invitation',
        acceptInvitation: 'v1/organization/user/accept-invitation',
        roles: 'v1/organization/roles',
        changeRole: 'v1/organization/user/{{userId}}/role/{{roleId}}',
      },
    },
  };
}

export function getI18nConfig(environment:PlatformEnv):I18nConfig {
  const { LANG, LOCALES, LOCALES_STORAGE_KEY } = environment;
  const locales = LOCALES ?? ['en', 'fr']; // defaults for tests - apps must provide env
  const storageKey = LOCALES_STORAGE_KEY!;
  const languages = Array.from(new Set(locales.map((locale: string) => locale.split('-')[0])));

  return {
    locales,
    storageKey,
    languages,
    lang: LANG,
    defaultLang: LANG ?? languages[0],
  };
}
