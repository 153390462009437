/**
 * # i18n Platform service
 *
 * > @see /apps/shared/doc/i18n.md
 *
 * This one is ONLY for Platform (menus etc)
 *
 * It will translate few sentences so use simple imnplementation (does not use big library)
 */
import type { Signal } from '@angular/core';
import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { Observable } from 'rxjs';
import { filter, map, ReplaySubject, take } from 'rxjs';

import { flattenJSON, isArray } from '@evc/web-components';

import type { EvcI18nService, TranslationKey, TranslationParams } from '../../types/i18n-service.type';

async function importLanguageFile(language:string) {
  return (() => {
    switch (language) {
      case 'fr':
        return import('../../../private-assets/i18n/fr.json');
      default:
        return import('../../../private-assets/i18n/en.json');
    }
  })()
  .then(module => module.default);
}

interface LangChangeEvent {
  lang: string;
  translations: Record<string, string>;
}

@Injectable({
  providedIn: 'root',
})
export class I18nPlatformService implements EvcI18nService {
  #destroyRef = inject(DestroyRef);

  // messages are flatten for easy get : `{ 'menus.applications.links.home': 'Accueil', ... }`
  #messages = signal<Record<string, string>>({});
  #lang = signal<string|undefined>(undefined);

  onLangChange$ = new ReplaySubject<LangChangeEvent>();
  onReady$: Observable<LangChangeEvent> = this.onLangChange$
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
      filter((event:LangChangeEvent) => event && event.lang === this.lang),
      take(1),
    );

  get lang():string {
    return this.#lang()!;
  }

  setLanguage(language:string):void {
    this.#lang.set(language);

    importLanguageFile(language)
      .then(messages => {
        const translations = flattenJSON(messages);

        this.#messages.set(translations);
        this.onLangChange$.next({ lang: language, translations });
      });
  }

  t(key: TranslationKey, interpolateParams?: TranslationParams):string {
    return this.#translate(key, interpolateParams);
  }

  t$(key: TranslationKey, interpolateParams?: TranslationParams):Observable<string> {
    return this.onLangChange$.pipe(
      takeUntilDestroyed(this.#destroyRef),
      map(() => this.#translate(key, interpolateParams)),
    );
  }

  tSignal(key: TranslationKey, interpolateParams?: TranslationParams):Signal<string> {
    return computed(() => this.#translate(key, interpolateParams));
  }

  #translate(key: TranslationKey, interpolateParams:TranslationParams = {}):string {
    const path = this.#flattenKey(key);

    let message = this.#messages()[path];

    if (!message) return key as string;

    for (const paramKey in interpolateParams) {
      const value = interpolateParams[paramKey] as string;
      message = message.replace(new RegExp(`{{${paramKey}}}`, 'g'), value);
    }

    return message;
  }

  #flattenKey(key: TranslationKey):string {
    return isArray(key) ? key.join('.') : key;
  }
}
